import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react'
import {users} from '../../api/users'
import {useRouter} from '../../utils/router'
import store from '../../stores/store'
import {toast} from 'react-toastify'
import Header from '../../parts/Header'
import Alert from "react-bootstrap/Alert";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner'
import persistentStore from "../../stores/persistentStore";

const Login = () => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [rememberMe, setRememberMe] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const router = useRouter()
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const namePlural = persistentStore.user?.settings.stripName?.plural
        ? persistentStore.user?.settings.stripName?.plural
        : 'strippenkaarten'

    useEffect(() => {
        // logout the user
        users.logout()
    }, [])

    const startLoading = () => setLoading(true)
    const stopLoading = () => setLoading(false)

    const setLoggedInCookie = () => {
        const domain = process.env.NODE_ENV === 'development'
            ? 'strippenkaart.local'
            : 'strippenkaart.app'
        const d = new Date()
        const validDays = 30
        d.setTime(d.getTime() + (validDays*24*60*60*1000));
        const expires = d.toUTCString();
        document.cookie = `logged_in=true;expires=${expires};domain=${domain}`
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setError('')
        store.triggerEvent('clicked_button_login')
        startLoading()

        users.login({username: username, password: password, remember_me: rememberMe})
            .then((response) => {
                stopLoading()
                if (response.success !== true) {
                    console.error('! error!')
                    console.error(response)
                    toast.error(response.error)
                    setError(response.error)
                    return
                }

                setLoggedInCookie()
                router.push('/customers')
            })
            .catch(error => {

                stopLoading()
                if (error.status === 'FAILURE_CREDENTIALS_INVALID' || error.title === 'FAILURE_CREDENTIALS_INVALID' || error.message === 'FAILURE_CREDENTIALS_INVALID') {
                    setError('Onjuiste gebruikersnaam of wachtwoord')
                    return
                }

                if (!error.response?.data && error.message) {
                    setError(error.message)
                    return
                }

                const response = error.response.data
                const errors = response.errors

                if(! errors) {
                    setError(t('Server sent invalid response'))
                    return
                }

                if (errors.title === 'FAILURE_CREDENTIALS_INVALID' || errors.title === 'FAILURE_IDENTITY_NOT_FOUND' || errors.title === 'Login failed: user not found') {
                    setError('Onjuiste gebruikersnaam of wachtwoord')
                    return
                }

                if (errors.detail === 'user not found') {
                    setError(t('This account is probably deleted from the system'))
                    return
                }

                const title = errors.title || ''
                if (['No valid subscription found', 'Expired user tried to login'].includes(title)) {
                    setError(t('Your account is expired'))
                    return
                }

                setError(t('Server sent invalid response'))
            })
    }

    return (
        <>
            <Header/>
            <main role="main" className="offset-md-3 col-md-5 pt-3 px-4">
                <form className="form-signin" onSubmit={handleSubmit} method="POST">

                    <h1 className="h3 mb-3 font-weight-normal">
                        Log in om je {namePlural} te beheren
                    </h1>

                    {error && <Alert variant='danger'>{error}</Alert>}

                    <label htmlFor="inputEmail" className="sr-only">E-mailadres</label>
                    <input
                        type="text"
                        id="inputEmail"
                        className="form-control mb-2"
                        placeholder={t('Your email address')}
                        name="email"
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value)
                        }}
                        autoComplete="username" required autoFocus
                    />

                    <label htmlFor="inputPassword" className="sr-only">Password</label>
                    <input type="password" id="inputPassword" className="form-control mb-2" placeholder={t("Password")}
                           name="password" value={password}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                           autoComplete="current-password" required/>

                    <div className="checkbox mb-3">
                        <label>
                            <input type="checkbox" value="remember_me"
                                   onChange={(e: ChangeEvent<HTMLInputElement>) => setRememberMe(e.target.checked)}
                            /> Onthoud mij
                        </label>
                    </div>

                    <Button variant='primary' id='btn-login' type="submit" className='btn-block' disabled={loading}>
                        {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                        {!loading && <span>{t('Login')}</span>}
                    </Button>
                    <Link to="/pricing" className="btn btn-link">{t('Register')}</Link>
                    <Link to="/requestpasswordreset" className="btn btn-link">Wachtwoord vergeten?</Link>

                    <p className="mt-5 mb-3 text-muted">
                        &copy; {(new Date()).getFullYear()} Paul Edenburg | Infinity Squared Solutions</p>
                </form>
            </main>
        </>
    )
}

export default observer(Login)
